<template>
	<div class="container">
		<div class="title">
			<div class="text">标签管理</div>
			<div class="meta-functions">
				<div class="function-item add" @click="toAddPage">
					<i class="iconfont icon-add refresh"></i>
					<span>添加</span>
				</div>
			</div>
		</div>
		<div class="table">
			<div class="table-header">
				<div class="row">
					<div class="col id">#</div>
					<div class="col flex-1">标题</div>
					<div class="col flex-1">排序号</div>
					<div class="col flex-1">状态</div>
					<div class="col operation">操作</div>
				</div>
			</div>
			<div class="table-body">
				<div class="row" v-for="(item,index) in dataList" :key="index">
					<div class="col id">
						{{index.id}}
					</div>
					<div class="col flex-1">{{item.tag_name}}</div>
					<div class="col flex-1">{{item.tag_sort}}</div>
					<div class="col flex-1">
						<span class="col" v-if="item.tag_status==1">显示</span>
						<span class="col" v-else>隐藏</span>
					</div>
					<div class="col operation">
						<div class="operation-item" title="编辑" :data-index="index" @click="toEditPage">
							<i class="iconfont icon-bianji"></i>
						</div>
					</div>
				</div>
			</div>
			<div class="paginator">
				<paginator :total="totalRecords" :size="filter.size" :page="filter.page" :change="onPageChanged" />
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getUsedCarTagList
	} from "@/api/used-car.js";
	import Paginator from "@/components/widgets/Paginator.vue";
	export default {
		components: {
			Paginator
		},
		data() {
			return {
				dataList: [],
				filter: {
					page: 1,
					size: 20,
				},
				totalRecords: 0,
			};
		},
		created() {
			this.loadData()
		},
		methods: {
			toAddPage() {
				this.$router.push({
					name: "UsedCarTagAdd",
				});
			},
			reloadUser(page) {
				if (page > 0) {
					this.filter.page = page;
				}
				this.loadData();
			},
			onPageChanged(page) {
				this.reloadUser(page);
			},
			toEditPage(e) {
				let index = e.currentTarget.dataset.index;
				this.$router.push({
					name: "UsedCarTagUpdate",
					params: {
						info: encodeURIComponent(JSON.stringify(this.dataList[index])),
					},
				});
			},
			loadData() {
				getUsedCarTagList(this.filter).then((res) => {
					if (res.status == 200) {
						this.dataList = res.data.data;
						this.totalRecords = res.data.total;
					}
				});
			}
		},
	};
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/filter.less");
	@import url("~@/assets/style/table.less");

	.container {
		.color-view {
			width: 14px;
			height: 14px;
			display: inline-block;
			border-radius: 8px;
			border: 1px solid gray;
			margin-right: 5px;
		}
	}
</style>
